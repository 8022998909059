import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    type: '',
    text: '',
    title: '',
    icon: '',
    active: false,
  }),
  actions: {
    set(notification) {
      this.text = notification.text
      this.type = notification.type
      this.title = notification.title
      this.icon = notification.icon
      this.active = true
    },
  },
})
