import validate from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/pages/runtime/validate.js";
import reset_45alerts_45global from "/app/middleware/resetAlerts.global.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  reset_45alerts_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/app/middleware/authenticated.js")
}