import {
  createDirectus,
  rest,
  realtime,
  authentication,
  uploadFiles,
  readFolders,
  createFolder,
  readMe,
  readUser,
  readUsers,
  readItems,
  createItem,
  readItem,
  updateItem,
  updateUser,
  createUser,
  deleteItems,
  deleteUser,
  deleteUsers,
  deleteItem,
  createItems,
  updateItems,
  passwordReset,
  createUsers,
  updateMe,
  deleteFile,
  deleteFiles,
} from '@directus/sdk'
import { useNotificationStore } from '~/store/notification'

export default defineNuxtPlugin(async ({ $route, $router, $config }) => {
  const notificationStore = useNotificationStore()

  const directus = createDirectus($config.public.cmsUrl)
    .with(authentication('cookie', { credentials: 'include' }))
    .with(rest({ credentials: 'include' }))
    .with(realtime())

  const retryStatusCodes = [401, 403, 408, 409, 425, 429, 500, 502, 503, 504]
  directus.globals.fetch = $fetch.create({
    async onResponseError({ response, options }) {
      if (
        !options.isRetry &&
        retryStatusCodes.includes(response.status) &&
        !response.url.includes('/auth/refresh') &&
        !response.url.includes('/auth/login')
      ) {
        const refreshResult = await directus.refresh()
        options.headers['Authorization'] = 'Bearer ' + refreshResult.access_token
        options.isRetry = true
        return
      }
      if (!response.url.includes('/items/notifications')) {
        switch (response.status) {
          case 401:
            if (!response.url.includes('/users/me')) {
              if (!response.url.includes('/auth/login')) {
                notificationStore.set({
                  title: 'Sitzung abgelaufen',
                  text: 'Bitte melden Sie sich erneut an.',
                  type: 'error',
                })
                if ($route && $route.path) {
                  $router.push('/auth/login?path=' + $route.path)
                } else {
                  $router.push('/auth/login')
                }
              }
              return Promise.reject(response) // return the error
            }
            break
          case 403:
            if (!response.url.includes('/auth/password/reset')) {
              notificationStore.set({
                title: 'Fehlende Berechtigungen',
                text: 'Leider besitzen Sie für diese Aktion nicht die erforderlichen Berechtigungen',
                type: 'error',
              })
            }
            break
          case 400:
            if (
              response.url.includes('/auth/refresh') &&
              $route &&
              $route.path !== '/auth/forward'
            ) {
              notificationStore.set({
                title: 'Sitzung abgelaufen',
                text: 'Bitte melden Sie sich erneut an.',
                type: 'error',
              })
              return $router.push('/auth/login')
            }
            notificationStore.set({
              title: 'Vorgang fehlgeschlagen',
              text: 'Ihre Anfrage war ungültig. Bitte überprüfen Sie Ihre Eingaben.',
              type: 'error',
            })
            return Promise.reject(response)

          default:
            notificationStore.set({
              title: 'Vorgang fehlgeschlagen',
              text: 'Es ist ein unerwarteter Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.',
              type: 'error',
            })
            break
        }
      }
    },
    retry: 1,
    retryStatusCodes,
  })

  const uploadFile = async (file, title, folderName = null, tags = null) => {
    let folderId = null

    try {
      if (folderName) {
        const folders = await directus.request(
          readFolders({
            filter: {
              name: {
                _eq: folderName,
              },
            },
            fields: ['id', 'name'],
          })
        )
        if (folders.length) {
          folderId = folders[0].id
        } else {
          const folder = await directus.request(
            createFolder(
              {
                name: folderName,
              },
              {
                fields: ['id', 'name'],
              }
            )
          )
          folderId = folder.id
        }
      }

      const formData = new FormData()
      formData.append('title', title)
      formData.append('tags', JSON.stringify(tags))
      formData.append('file', file)
      if (folderId) {
        formData.append('folder', folderId)
      }
      const fileResponse = await directus.request(uploadFiles(formData))
      return fileResponse
    } catch (error) {
      console.error('Error during file upload:', error)
      throw error
    }
  }

  return {
    provide: {
      cms: directus,
      readMe,
      readUser,
      readUsers,
      createUser,
      createUsers,
      updateMe,
      updateUser,
      deleteUser,
      deleteUsers,
      readItem,
      readItems,
      createItem,
      createItems,
      updateItem,
      updateItems,
      deleteItem,
      deleteItems,
      passwordReset,
      uploadFile,
      deleteFile,
      deleteFiles,
    },
  }
})
