<template>
  <div>
    <NuxtLayout>
      <NuxtPage class="page-container" />
    </NuxtLayout>
  </div>
</template>
<script>
export default {
  setup() {
    useHead({ title: 'Start' })
  },
}
</script>

<style lang="scss">
:root {
  --inset-top: env(safe-area-inset-top);
  --inset-bottom: env(safe-area-inset-bottom);
}

body,
.v-navigation-drawer {
  padding-top: var(--inset-top) !important;
  padding-bottom: var(--inset-bottom) !important;
}

.v-snackbar {
  top: calc(var(--inset-top) + 64px) !important;
}

.v-app-bar:not(.v-app-bar--bottom) {
  padding-top: var(--inset-top) !important;
}

.v-app-bar--bottom {
  padding-bottom: var(--inset-bottom) !important;
}

.v-bottom-sheet .v-sheet,
.v-bottom-sheet .v-card {
  padding-bottom: calc(var(--inset-bottom) + 20px) !important;
}

.v-bottom-navigation {
  padding-bottom: var(--inset-bottom) !important;
  height: calc(var(--inset-bottom) + 56px) !important;
}

.blur-enter-active {
  transition: all 0.2s;
  transition-delay: 0.2s;
}

.blur-leave-active {
  transition: all 0.2s;
}

.blur-enter-from,
.blur-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.striped-new {
  background: repeating-linear-gradient(
    -55deg,
    #3f51b505,
    #3f51b505 10px,
    #3f51b510 10px,
    #3f51b510 20px
  );
}
</style>
