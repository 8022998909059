import { defineStore } from 'pinia'

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({
    rotatingOverview: { active: false, user: {}, wishesUpdated: false, absencesUpdated: false },
    administrativeOverview: { active: false, user: {} },
    overview: { active: false, facility: {}, parentFacility: {} },
    settings: {
      active: false,
      facility: {},
      image: null,
      disabled: false,
    },
    parttime: {
      active: false,
      facility: {},
      image: null,
      disabled: false,
    },
    external: { active: false, rotation: null, facility: {} },
    edit: { active: false, facility: {}, parentFacility: {}, image: null },
    competence: { active: false, facility: {}, image: null },
    unit: { active: false, unit: {}, facility: {} },
    unitEdit: { active: false, unit: {}, facility: {} },
    twoFactorAuth: { active: false },
    labelEdit: { active: false, facilityId: null },
    dutyEdit: { active: false, facilityId: null },
  }),
  actions: {
    open(sidebar) {
      switch (sidebar.type) {
        case 'rotatingOverview':
          this.rotatingOverview.active = true
          this.rotatingOverview.user = sidebar.user
          this.rotatingOverview.wishesUpdated = false
          this.rotatingOverview.absencesUpdated = false
          break
        case 'administrativeOverview':
          this.administrativeOverview.active = true
          this.administrativeOverview.user = sidebar.user
          break
        case 'overview':
          this.overview.active = true
          this.overview.facility = sidebar.facility
          this.overview.parentFacility = sidebar.parentFacility
          break
        case 'settings':
          this.settings.active = true
          this.settings.facility = sidebar.facility
          this.settings.image = sidebar.image
          this.settings.disabled = sidebar.disabled
          break
        case 'parttime':
          this.parttime.active = true
          this.parttime.facility = sidebar.facility
          this.parttime.image = sidebar.image
          this.parttime.disabled = sidebar.disabled
          break
        case 'external':
          this.external.active = true
          this.external.facility = sidebar.facility
          this.external.rotation = sidebar.rotation
          break
        case 'edit':
          this.edit.active = true
          this.edit.facility = sidebar.facility
          this.edit.parentFacility = sidebar.parentFacility
          this.edit.image = sidebar.image
          break
        case 'competence':
          this.competence.active = true
          this.competence.facility = sidebar.facility
          this.competence.image = sidebar.image
          break
        case 'unit':
          this.unit.active = true
          this.unit.facility = sidebar.facility
          this.unit.unit = sidebar.unit
          break
        case 'unitEdit':
          this.unitEdit.active = true
          this.unitEdit.facility = sidebar.facility
          this.unitEdit.unit = sidebar.unit
          break
        case 'twoFactorAuth':
          this.twoFactorAuth.active = true
          break
        case 'labelEdit':
          this.labelEdit.active = true
          this.labelEdit.facilityId = sidebar.facilityId
          break
        case 'dutyEdit':
          this.dutyEdit.active = true
          this.dutyEdit.facilityId = sidebar.facilityId
          break
      }
    },
    close(type) {
      switch (type) {
        case 'rotatingOverview':
          this.rotatingOverview.active = false
          this.rotatingOverview.user = {}
          break
        case 'administrativeOverview':
          this.administrativeOverview.active = false
          this.administrativeOverview.user = {}
          break
        case 'overview':
          this.overview.active = false
          this.overview.facility = {}
          this.overview.parentFacility = {}
          break
        case 'settings':
          this.settings.active = false
          this.settings.facility = {}
          this.settings.image = null
          this.settings.disabled = false
          this.settings.external = false
          break
        case 'parttime':
          this.parttime.active = false
          this.parttime.facility = {}
          this.parttime.image = null
          this.parttime.disabled = false
          this.parttime.external = false
          break
        case 'external':
          this.external.active = false
          this.external.facility = {}
          this.external.rotation = {}
          break
        case 'edit':
          this.edit.active = false
          this.edit.facility = {}
          this.edit.parentFacility = {}
          this.edit.image = null
          break
        case 'competence':
          this.competence.active = false
          this.competence.facility = {}
          this.competence.image = null
          break
        case 'unit':
          this.unit.active = false
          this.unit.facility = {}
          this.unit.unit = {}
          break
        case 'unitEdit':
          this.unitEdit.active = false
          this.unitEdit.facility = {}
          this.unitEdit.unit = {}
          break
        case 'twoFactorAuth':
          this.twoFactorAuth.active = false
          break
        case 'labelEdit':
          this.labelEdit.active = false
          this.labelEdit.faciltiyId = null
          break
        case 'dutyEdit':
          this.dutyEdit.active = false
          this.dutyEdit.facilityId = null
          break
      }
    },
    setWishesUpdateStatus(status) {
      this.rotatingOverview.wishesUpdated = status
    },
    setAbsencesUpdateStatus(status) {
      this.rotatingOverview.absencesUpdated = status
    },
  },
})
