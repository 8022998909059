import payload_plugin_d0dIXcGCxb from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.27.4_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_DGqXX7lVbs from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import path_client_2CrkUkjKpA from "/app/plugins/path.client.ts";
import unhead_r7f67p77SF from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vtj551XmCN from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_iU4JXYvZ4r from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Bj5b0X535y from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RgpEBrnI1f from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0jY5aXtm92 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UxRbGrISbX from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.27.4_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5UrXpjgv7z from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._2opu37nnxwf6oqh5yh5uzhdy2m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import deeplinks_CV6iFr87A8 from "/app/plugins/deeplinks.js";
import directus_BNSudx5Icl from "/app/plugins/directus.js";
import error_handler_VFH3VvK7yG from "/app/plugins/error-handler.js";
import global_mixins_FeoDNy7nGB from "/app/plugins/global-mixins.js";
import history_helpers_JIsi3y9CQn from "/app/plugins/history-helpers.js";
import mitt_ZNXaeqsgM5 from "/app/plugins/mitt.js";
import pinia_48xmdi2HHl from "/app/plugins/pinia.ts";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  payload_plugin_d0dIXcGCxb,
  revive_payload_client_DGqXX7lVbs,
  path_client_2CrkUkjKpA,
  unhead_r7f67p77SF,
  router_vtj551XmCN,
  payload_client_iU4JXYvZ4r,
  navigation_repaint_client_Bj5b0X535y,
  check_outdated_build_client_RgpEBrnI1f,
  chunk_reload_client_0jY5aXtm92,
  plugin_vue3_UxRbGrISbX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5UrXpjgv7z,
  deeplinks_CV6iFr87A8,
  directus_BNSudx5Icl,
  error_handler_VFH3VvK7yG,
  global_mixins_FeoDNy7nGB,
  history_helpers_JIsi3y9CQn,
  mitt_ZNXaeqsgM5,
  pinia_48xmdi2HHl,
  sentry_client_GoGQuZo4Et,
  vuetify_7h9QAQEssH
]