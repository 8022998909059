import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'
import { de } from 'vuetify/locale'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import DateFnsAdapter from '@date-io/date-fns'
import dateFnsDe from 'date-fns/locale/de'

export default defineNuxtPlugin((app) => {
  const light = {
    dark: false,
    colors: {
      primary: '#2F523E',
      secondary: '#A8ABAC',
      'secondary-darken': '#888888',
      accent: '#FF8966',
      info: '#FBB03B',
      success: '#4CAF50',
      warning: '#FB8C00',
      white: '#FFFFFF',
      black: '#14242E',
      font: '#2F523E',
      'light-grey': '#E6E6E6',
      grey: '#CCCCCC',
      'grey-darken': '#A8ABAC',
      'tls-green': '#4CAF50',
    },
  }

  const vuetify = createVuetify({
    ssr: false,
    locale: {
      locale: 'de',
      messages: { de },
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        light,
      },
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    date: {
      adapter: DateFnsAdapter,
      locale: {
        de: dateFnsDe,
      },
    },
    components: {
      ...components,
      ...labsComponents,
    },
    directives,
  })

  app.vueApp.use(vuetify)
})
